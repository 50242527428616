@media screen and (min-width: 1280px) {
  :root {
    --quote-panel-width: 25%;
    --user-message-width: 33%;
    --rm-step-progress-connector--width: 48px;
    --rm-step-progress-connector--margin: 0 16px;
  }
}
@media screen and (max-width: 1279px) {
  :root {
    --quote-panel-width: 33%;
    --user-message-width: 33%;
    --rm-step-progress-connector--width: 48px;
    --rm-step-progress-connector--margin: 0 16px;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --quote-panel-width: 40%;
    --user-message-width: 50%;
    --rm-step-progress-connector--width: 48px;
    --rm-step-progress-connector--margin: 0 16px;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --quote-panel-width: 60%;
    --user-message-width: 75%;
    --rm-step-progress-connector--width: 24px;
    --rm-step-progress-connector--margin: 0 8px;
  }
}
@media screen and (max-width: 480px) {
  :root {
    --quote-panel-width: 100%;
    --user-message-width: 100%;
  }
}
/*# sourceMappingURL=media.010d99ce.css.map */
